import axios from "axios";

export default {
  methods: {
    validateEmail(emailAdress) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Prevents multiple @'s

      if (emailAdress.length == 0) {
        return 'border-gray-300';
      }

      return regex.test(emailAdress) == true ? 'border-green-500' : 'border-red-500';
    },
    checkPassword(password) {
      const number = new RegExp('.*[0-9]');
      const lowerAlphabet = new RegExp('.*[a-z]');
      const upperAlphabet = new RegExp('.*[A-Z]');
      const symbols = new RegExp('.*[!@?#$£*]');
      const length = new RegExp('.{8,}');

      const global = new RegExp('.*[^A-z0-9!@?#$£*]');

      const requirements = {
        hasNumber: false,
        hasLowerAlpha: false,
        hasUpperAlpha: false,
        hasSymbol: false,
      };

      let i = 0;
      let total = 0; // Total = how many requirements have been met

      [number, lowerAlphabet, upperAlphabet, symbols, length].forEach((RegEx) => {
        const requirement = Object.keys(requirements)[i]; // Get correct requirements ID for provided RegEx
        requirements[requirement] = RegEx.test(password); // Has requirement been met

        (requirements[requirement]) === true ? total++ : i++;
      });

      if (password.length == 0) {
        return 'border-gray-300';
      }

      if (password.length >= 8 && global.test(password) !== false) {
        return 'border-green-500'; // Password is fine to use - Doesn't use any Ilegal characters
      }

      const colours = ['border-red-500', 'border-red-500', 'border-orange-500', 'border-amber-500', 'border-yellow-500', 'border-green-500'];

      // console.log(`Total: ${total} & Colour: ${colours[total]}`); - Debug

      return colours[total];
    },
    matchingPassword(password, confirmPassword) {

      if (confirmPassword.length == 0) {
        return 'border-gray-300';
      }

      if (password == confirmPassword) {
        return 'border-green-500';
      } else {
        return 'border-red-500';
      }
    },
    checkIfValidUUID(str) { // Reference https://melvingeorge.me/blog/check-if-string-valid-uuid-regex-javascript
      // Regular expression to check if string is a valid UUID
      const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
      return regexExp.test(str);
    },
    limitWords(str, charLimit) { // Limit the total character count of a provided String
      return str.length > charLimit ? str.substring(0, charLimit - 3) + "..." : str;
    },
    formatDate(date) {
      // 8 Mar 22

      const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      return date.getDate() + " " + Months[date.getMonth()] + " " + String(date.getFullYear()).substr(2, 3);

    },

    formatDateTime(date) {
      // 8 Mar 22 @ 07:36 PM

      const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      return date.getDate() + " " + Months[date.getMonth()] + " " + String(date.getFullYear()).substr(2, 3) + " @ " + (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + " " + (date.getHours() >= 12 ? 'PM' : 'AM');

    },

		getResources() { // Get Resources about Bikes - I.e. Brands, Types ...

      axios.post('bike/get', {
        requestReason: "resources"
        
      }).then((response) => {
        console.log(response.data.bike);
        return response.data.bike;

      }).catch((e) => {
        console.warn(e);

        return null;
      });
    },

    debug(e) {
      console.log(e);
    },

    handleClick(element) {
			this.$router.push("/" + element);
		},

    toBoolean(value) {

      if (typeof value == 'string') {
        value = parseInt(value);
      }

      if (typeof value == 'number') {
        value = Boolean(value);
      }

      return typeof value == 'boolean' ? value : false;
    }
    

  },
};
