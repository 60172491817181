<template>
	<div class="app body-bg min-h-screen">
		<Navbar />
		<div class="pt-12 md:pt-20 pb-6 px-2 md:px-0">
				<main class="bg-white mx-auto p-8 md:pt-12 my-10 rounded-lg shadow-2xl" :class="[['login', 'register', 'forgot', 'reset'].includes($route.name) ? 'max-w-lg' : 'max-w-fit']">
          <Suspense>
            <template #default>
              <router-view/>
            </template>

            <template #fallback>
              <div class="z-50 w-screen h-screen body-bg absolute left-0 top-0"></div>
            </template>
          </Suspense>
				</main>
		</div>
	</div>
</template>

<script>

import {mapGetters} from "vuex";
import shared from '@/shared';

import Navbar from '@/components/Navbar.vue';
import axios from "axios";


export default {
  name: 'App',
  components: {
    Navbar,
  },
  computed: {
		...mapGetters(['user'])
	},
  mixins: [
    shared,
  ],
  methods: {

    async created() {

      await this.$store.dispatch('requestsReceived', false);

      await axios.get('user').then(
          (response) => {
            console.log(response.data);
            localStorage.setItem('UserData', JSON.stringify(response.data.data));
            this.$store.dispatch('user', response.data.data);
            this.$store.dispatch('requestsReceived', true);

            // this.allowedPaths();
          }
      ).catch((error) => {
        console.warn(error);
        // this.allowedPaths();

        this.$store.dispatch('requestsReceived', true);
      });
    },

  	// Get Resources about Bikes - I.e. Brands, Types ...

		async getResources() {

      await axios.post('bike/get', {
        requestReason: "resources"
      }).then((response) => {
        this.$store.dispatch('bikeResources', response.data.bike);

      }).catch((e) => {
        console.warn(e);
      });
    },


    async allowedPaths() {
      let requestedPath = this.$route.name;

      console.log('requested Route: ' + requestedPath);
      console.log(this.$route);


      if (['login', 'register', 'forgot'].includes(requestedPath)) {
        // Allow
        return;

      } else if (this.user === null) {
        this.handleClick('');
        return;

      } else if (parseInt(this.user.AccountRole) == 1) {

        if (['officer-home'].includes(requestedPath)) {
          // Allow
          this.handleClick('officer');
          return;

        } else {
          this.handleClick('officer');

        }
      }
    }




  },
  created() {
    this.created();
    this.getResources();
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');

* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}

.body-bg {
	background-color: #1C8EF9;
	background-image: linear-gradient(342deg, #121212 0%, #141414 74%);
}

</style>
