import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import store from '../store/index';
import shared from '@/shared';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Citizen/Home'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Verification/Login'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "Register" */ '../views/Verification/Register'),
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import(/* webpackChunkName: "Forgot" */ '../views/Verification/Forgot'),
  },
  {
    path: '/reset/:verificationCode',
    name: 'reset',
    component: () => import(/* webpackChunkName: "Reset" */ '../views/Verification/Reset'),
  },
  {
    path: '/app/registerbike',
    name: 'registerbike',
    component: () => import(/* webpackChunkName: "RegisterBike" */ '../views/Citizen/RegisterBike')
  },
  {
    path: '/app/view/:bikeUUID',
    name: 'viewbike',
    component: () => import(/* webpackChunkName: "ViewBike" */ '../views/Citizen/ViewBike')
  },
  {
    path: '/app/edit/:bikeUUID',
    name: 'editbike',
    component: () => import(/* webpackChunkName: "EditBike" */ '../views/Citizen/EditBike')
  },
  {
    path: '/officer',
    name: 'officer-home',
    component: () => import(/* webpackChunkName: "OfficerHome" */ '../views/PoliceOfficer/Home')
  },
  {
    path: '/administrator',
    name: 'administrator-home',
    component: () => import(/* webpackChunkName: "AdministratorHome" */ '../views/PoliceAdministrator/Home')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.beforeEach((to, from, next) => {
  let user = store.getters.user;

  // Require no checks whilst accessing verification pages

  if (['login', 'register', 'forgot', 'reset'].includes(to.name)) {
    next();
    return;
  }
  
  if (!(shared.methods.checkIfValidUUID(localStorage.getItem('UserToken')))) {
    next({name: 'login'});
    return;

  } else {

    if (user === null) {
      let userData = localStorage.getItem('UserData');

      if (userData !== null) {
        user = JSON.parse(userData);
        store.replaceState = userData;

      } else {
        next({name: 'login'});
        return;
      }

    }

    if (user !== null) {

      if (parseInt(user.AccountRole) === 1) {


        if (['home'].includes(to.name)) {
          next({name: 'officer-home'});
          return;

        } else if ([].includes(to.name)) {
          next();
          return;
        }

      } else if (parseInt(user.AccountRole) == 2) {

        if (['home'].includes(to.name)) {
          next({name: 'administrator-home'});
          return;
        }
        // ToDo

      } else {
        next();
        return;
      }

      next();
      return;
    }
  }

});


export default router;
