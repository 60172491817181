import { createStore } from 'vuex';

export default createStore({
  state() {
    return {
      user: null,
      bikeResources: null,
      requestsReceived: false,
      showStolenModal: false,
      showDeleteModal: false,
      plottedCoordinates: null,
      stolenBikeData: null
    };
  },
  getters: {
    user: (state) => state.user,
    bikeResources: (state) => state.bikeResources,
    requestsReceived: (state) => state.requestsReceived,
    showStolenModal: (state) => state.showStolenModal,
    showDeleteModal: (state) => state.showDeleteModal,
    plottedCoordinates: (state) => state.plottedCoordinates,
    stolenBikeData: (state) => state.stolenBikeData,
  },

  mutations: {
    user(state, user) {
      state.user = user;
    },
    bikeResources(state, bikeResources) {
      state.bikeResources = bikeResources;
    },
    requestsReceived(state, requestsReceived) {
      state.requestsReceived = requestsReceived;
    },
    showStolenModal(state, showStolenModal) {
      state.showStolenModal = showStolenModal;
    },
    showDeleteModal(state, showDeleteModal) {
      state.showDeleteModal = showDeleteModal;
    },
    plottedCoordinates(state, plottedCoordinates) {
      state.plottedCoordinates = plottedCoordinates
    },
    stolenBikeData(state, stolenBikeData) {
      state.stolenBikeData = stolenBikeData
    }
  },


  actions: {
    user(context, user) {
      context.commit('user', user);
    },
    bikeResources(context, bikeResources) {
      context.commit('bikeResources', bikeResources);
    },
    requestsReceived(context, requestsReceived) {
      context.commit('requestsReceived', requestsReceived);
    },
    showStolenModal(context, showStolenModal) {
      context.commit('showStolenModal', showStolenModal);
    },
    showDeleteModal(context, showDeleteModal) {
      context.commit('showDeleteModal', showDeleteModal);
    },
    plottedCoordinates(context, plottedCoordinates) {
      context.commit('plottedCoordinates', plottedCoordinates);
    },
    stolenBikeData(context, stolenBikeData) {
      context.commit('stolenBikeData', stolenBikeData);
    }
  },
  
  modules: {
  },
});
