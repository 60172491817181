<template>
  <nav class="bg-gray-800 text-gray-200 shadow-lg">
    <div class="container mx-auto">
      <div class="sm:flex justify-around">
        <!-- Site Logo -->

        <img
          @click="this.$router.push('/')"
          class="p-3 cursor-pointer"
          src="@/assets/Logo.png"
          alt="logo"
          width="174"
        />

        <div
          class="toggle hidden md:flex w-full md:w-auto text-right text-bold mt-5 md:mt-0 border-t-2 border-blue-900 md:border-none justify-around">
          <!-- Left of Right (Map) -->

          <template v-if="!user">
            <ul class="text-gray-400 sm:self-center border-t sm:border-none">
              <!-- Login -->
              <li class="sm:inline-block hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                <router-link to="/login" class="p-3 hover:text-white">Login</router-link>
              </li>
            </ul>
          </template>

          <!-- Right of Right (Register, My Account) -->

          <ul class="text-gray-400 sm:self-center border-t sm:border-none">
            <li class="sm:inline-block hover:bg-purple-500 bg-purple-600 text-white px-3 py-2 rounded-md text-sm font-medium">
              <div class="p-1 px-2">
                <div class="group relative">
                  <template v-if="!user">
                    <!-- User is *not* logged in -->
										<button class="hover:text-white" href="javascript:void(0)" @click="handleClick('register')">Register</button>
                  </template>

                  <template v-else>
                    <!-- User is logged in -->

                    <button class="hover:text-white" href="javascript:void(0)">My Account</button>
                    <nav tabindex="0" class="border-gray-800 bg-white invisible rounded w-60 absolute text-left top-full transition-all opacity-0 group-focus-within:visible group-focus-within:opacity-100 group-focus-within:translate-y-5">

                      <ul class="py-1 text-gray-600">

                        <li>
                          <h6 href="#" class="block px-4 py-2 text-gray-500">Signed in as</h6>
													<p class="block px-6 text-gray-700 font-bold">{{ user.Email }}</p>
                        </li>

												<hr class="h-0 my-2 border border-solid border-t-0 border-gray-600 opacity-25" />

												<li>
                          <a class="block px-4 py-2 hover:bg-gray-100" href="javascript:void(0)" @click="handleClick('logout')">Sign out</a>
                        </li>

                      </ul>

                    </nav>
                  </template>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "Nav-bar",
  methods: {
    handleClick(element) {
      if (element === "logout") {
        localStorage.removeItem("UserToken");
        localStorage.removeItem("UserData");
        axios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('UserToken');
        this.$store.dispatch("user", null);

        new Promise((resolve) => setTimeout(resolve, 10));
        this.$router.push("/login");
      } else {
        this.$router.push("/" + element);
      }
    },
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["requestsReceived"]),
  },
};
</script>

<style></style>
