import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import './assets/tailwind.css';
import router from './router';
import '@/axios';
import '@/assets/styles/main.css';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

import VueGoogleMaps from "@fawmi/vue-google-maps";

createApp(App)
    .use(router)
    .use(store)
    .use(VueGoogleMaps, {
        load: {
          key: "AIzaSyDVAky7FJmO4A166sgtpXQMi6Lz0p3xoxU",
          libraries: "visualization"
        }
    })
    .use(Toast, {
      maxToasts: 3,
      newestOnTop: true
    })
    .component('fa', FontAwesomeIcon)
    .mount('#app');
